import React, { useCallback, useEffect, useState } from "react";
import { DefaultDashboardProps } from "./plasmic/remms_4_all/PlasmicDashboard";
import { BannerEntry, ShowLatestPortfolioResponse, PortfolioVersion, RealtyListEntry } from "../api/types";
import DashboardEntry from "./DashboardEntry";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import DashboardBanner from "./DashboardBanner";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isNil, partition } from "lodash";
import LoadingScreen from "./custom/LoadingScreen";
import PlasmicDashboard from "./plasmic/remms_4_all/PlasmicDashboard";
import { scrollElementIntoView } from "../utils";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface DashboardProps extends DefaultDashboardProps, WithAuthenticatedApiServiceProps {}

const enum MoreSelectActions {
  userAgreement = 1,
  importExport = 2,
}

function Dashboard_({ authenticatedApiService, ...props }: DashboardProps, ref: HTMLElementRefOf<"div">) {
  const { t } = useTranslation();

  const [realtyList, setRealtyList] = useState<RealtyListEntry[] | undefined>(undefined);
  const [archivedRealtyList, setArchivedRealtyList] = useState<RealtyListEntry[]>([]);
  const [banner, setBanner] = useState<BannerEntry[] | undefined>(undefined);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [showPortfolioModal, setShowPortfolioModal] = React.useState(false);
  const [portfolioInfo, setPortfolioInfo] = useState<ShowLatestPortfolioResponse | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  const [disablePortfolioIndexButton, setDisablePortfolioIndexButton] = useState<boolean>(false);
  const [oldPortfoliosFetched, setOldPortfoliosFetched] = useState<boolean>(false);
  const [displayOldPortfolios, setDisplayOldPortfolios] = useState<boolean>(false);
  const [oldPortfolios, setOldPortfolios] = useState<PortfolioVersion[]>([]);

  const [portfolioCreationStarted, setPortfolioCreationStarted] = useState<boolean>(false);
  const [portfolioCreationToastId, setPortfolioCreationToastId] = useState<string | undefined>(undefined);

  const handlePortfolioCreationStart = (toastId: string) => {
    setPortfolioCreationStarted(true);
    setPortfolioCreationToastId(toastId);
    if (oldPortfoliosFetched) {
      // We need to refresh them after creating a new portfolio
      fetchOldPortfolios();
    }
  };

  useEffect(() => {
    if (portfolioCreationStarted) {
      const intervalId = setInterval(async () => {
        const response = await authenticatedApiService.portfolioVersions.get();
        if (isSuccessful(response)) {
          setPortfolioInfo(response.data);
          if (response.data.portfolio_version?.portfolio_report?.pdf_report_state !== "pending") {
            setPortfolioCreationStarted(false);
            clearInterval(intervalId);
            if (portfolioCreationToastId) {
              toast.success(t("portfolioModal.created"), { id: portfolioCreationToastId, duration: 1000 });
              setPortfolioCreationToastId(undefined);
            }
          }
        }
      }, 1000); // Poll every 1000ms (1 second)

      return () => clearInterval(intervalId);
    }
  }, [portfolioCreationStarted, authenticatedApiService, portfolioCreationToastId, t]);

  const handleApiError = useApiErrorHandler()[1];

  const ROOT_URL = new URL(window.REMMS4ALL.rootUrl);
  const DIRECT_UPLOAD_URL = new URL("/rails/active_storage/direct_uploads", ROOT_URL);

  const setRealties = (realties: RealtyListEntry[]) => {
    const [activeRealties, archivedRealties] = partition(realties, (realty) => realty.archived_at === null);
    setRealtyList(activeRealties);
    setArchivedRealtyList(archivedRealties);
  };

  const fetchRealties = useCallback(() => {
    authenticatedApiService.dashboard.listRealties().then((response) => {
      if (isSuccessful(response)) {
        setRealties(response.data);
      } else {
        handleApiError(response);
      }
    });
  }, [authenticatedApiService, handleApiError]);

  const fetchBanner = useCallback(() => {
    authenticatedApiService.banner.get().then((response) => {
      if (isSuccessful(response)) {
        setBanner(response.data);
      } else {
        handleApiError(response);
      }
    });
  }, [authenticatedApiService, handleApiError]);

  const fetchPortfolioInfo = useCallback(() => {
    setDisablePortfolioIndexButton(true);
    authenticatedApiService.portfolioVersions.get().then((response) => {
      if (isSuccessful(response)) {
        setPortfolioInfo(response.data);
      } else {
        handleApiError(response);
      }
    });
    setDisablePortfolioIndexButton(false);
  }, [authenticatedApiService, handleApiError]);

  const handleMoreSelection = (value: MoreSelectActions | null) => {
    switch (value) {
      case MoreSelectActions.userAgreement:
        navigate(ApplicationRoutes.getPath("userSettings") + "#usageAgreementUserSettings");
        break;
      default:
        console.error("I do not know what to do with this value");
    }
  };

  const toggleOldPortfolios = () => {
    if (!oldPortfoliosFetched) {
      fetchOldPortfolios();
    }
    setDisplayOldPortfolios(!displayOldPortfolios);
  };

  const fetchOldPortfolios = () => {
    authenticatedApiService.portfolioVersions.index().then((response) => {
      if (isSuccessful(response)) {
        setOldPortfolios(response.data);
        setOldPortfoliosFetched(true);
      } else {
        handleApiError(response);
      }
    });
  };

  useEffect(() => {
    fetchRealties();
    fetchBanner();
    fetchPortfolioInfo();
  }, [fetchRealties, fetchBanner, fetchPortfolioInfo]);

  const loading =
    realtyList === undefined || archivedRealtyList === undefined || banner === undefined || portfolioInfo === undefined;

  React.useEffect(() => {
    if (!loading && location.hash) {
      setTimeout(() => {
        scrollElementIntoView(location.hash.substring(1));
      });
    }
  }, [loading, location.hash]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PlasmicDashboard
      portfolioEnabled={portfolioInfo?.portfolio_enabled}
      root={{ ref }}
      realtyList={{
        children: realtyList.map((realty) => (
          <DashboardEntry onListChange={fetchRealties} key={realty.id} realty={realty} />
        )),
      }}
      noArchivedRealties={archivedRealtyList.length === 0}
      archivedRealtyList={{
        children: archivedRealtyList.map((realty) => (
          <DashboardEntry onListChange={fetchRealties} key={realty.id} realty={realty} />
        )),
      }}
      bannerList={{
        children: banner.map((b) => <DashboardBanner key={b.id} id={b.id} title={b.title} text={b.text} />),
      }}
      moreSelect={{
        onChange: handleMoreSelection,
      }}
      showImportModal={showImportModal}
      importButton={{
        isDisabled: false,
        onClick: () => setShowImportModal(true),
      }}
      importModal={{
        onClose: () => setShowImportModal(false),
        onImportDone: fetchRealties,
        directUploadUrl: DIRECT_UPLOAD_URL.toString(),
        authenticatedApiService: authenticatedApiService,
        excelTemplateUrl: new URL("REMMS_Importer.xlsx", ROOT_URL).toString(),
      }}
      showPortfolioModal={showPortfolioModal}
      newPortfolioButton={{
        isDisabled: isNil(portfolioInfo?.esg_questionnaire),
        onClick: () => setShowPortfolioModal(true),
      }}
      portfolioEntryList={{
        portfolioButton: {
          onClick: () => setShowPortfolioModal(true),
        },
        portfolioInfo: portfolioInfo,
        oldPortfoliosDisplayed: displayOldPortfolios,
        portfolioIndexButton: {
          isDisabled: disablePortfolioIndexButton,
          onClick: () => toggleOldPortfolios(),
        },
        oldPortfolios: displayOldPortfolios ? oldPortfolios : [],
        onPortfolioDelete: (portfolio: PortfolioVersion) => {
          if (oldPortfolios.find((p) => p.id === portfolio.id)) {
            setOldPortfolios(oldPortfolios.filter((p) => p.id !== portfolio.id));
          } else {
            fetchPortfolioInfo();
            oldPortfoliosFetched && fetchOldPortfolios();
          }
        },
      }}
      portfolioModal={{
        onClose: () => setShowPortfolioModal(false),
        authenticatedApiService: authenticatedApiService,
        esgQuestionnaire: portfolioInfo?.esg_questionnaire,
        onPortfolioCreationStart: handlePortfolioCreationStart,
      }}
      esgPortfolioName={portfolioInfo?.esg_questionnaire?.["ESG_QUEST_PORTF"] ?? ""}
      portfolioNameSubtitle={isNil(portfolioInfo?.esg_questionnaire) ? <></> : undefined}
    />
  );
}

const Dashboard = withAuthenticatedApiService(React.forwardRef(Dashboard_));
export default Dashboard;
